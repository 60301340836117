import EventCalendar from 'components/calendar/EventCalendar';
import Button from '../../../../../components/fields/Button';
import { useEffect, useState } from 'react';
import { BusinessArticle } from '../../../../../apiplatform/interfaces/BusinessArticle';
import { useParams } from 'react-router-dom';
import TimeLine from './components/TimeLine';
import useRetrieve from '../../../../../hooks/useRetrieve';
import { PagedCollection } from '../../../../../apiplatform/interfaces/Collection';
import moment from 'moment';
import { PageCard } from 'components/card/PageCard';
import TimePicker from './components/TimePicker';
import useCreate from 'hooks/useCreate';
import { ScheduleArticleSending } from 'apiplatform/interfaces/ScheduleArticleSending';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';

interface CalendarProps {
  article?: BusinessArticle;
  onScheduleConfirmed?: () => void; 
  close?: () => void;

}

interface CalendarEventDateTime {
  date: string;
  hour: string;
  minute: string;
  period: string;
}

const Calendar: React.FC<CalendarProps> = ({ article, onScheduleConfirmed, close }) => {
  const today = new Date();
  const { id } = useParams<{ id: string; ctid: string }>();
  const [showCalendarOnSmallScreen, setShowCalendarOnSmallScreen] =
    useState(false);

  const { retrieved, loading } = useRetrieve<PagedCollection<BusinessArticle>>(
    `/businesses/${id}/scheduled_articles?scheduledAt[after]=${moment(
      today
    ).format('YYYY-MM-DD')}&order[scheduledAt]=asc&perPage=20`
  );

  const {
    create,
    loading: creating,
    created,
  } = useCreate<ScheduleArticleSending>(
    `/businesses/${id}/schedule_article_sending`
  );

  useEffect(() => {
    if (created) {
      article.scheduledAt = created.time;
      if (onScheduleConfirmed) {
        onScheduleConfirmed();
      }
    }
  }, [created, article, onScheduleConfirmed]);

  const [eventDateTime, setEventDateTime] = useState<CalendarEventDateTime>({
    date: today.toLocaleDateString('en-CA'),
    hour: moment(today).add(1, 'hour').format('h'),
    minute: moment(today).add(1, 'hour').format('mm'),
    period: moment(today).add(1, 'hour').format('A'),
  });

  const getSelectedDateTime = () => {
    return moment(
      `${eventDateTime.date} ${eventDateTime.hour}:${eventDateTime.minute} ${eventDateTime.period}`,
      'YYYY-MM-DD h:mm A'
    );
  };

  const isFutureDate = () => {
    const selectedDate = getSelectedDateTime();
    const currentDate = moment(new Date());
    return selectedDate.isAfter(currentDate);
  };

  return (
    <PageCard>
      <div className="grid h-full w-full rounded-[25px] md:grid-cols-8">
        <div
          className={`my-4 hidden rounded-[20px] dark:text-white md:col-span-4 md:col-start-1 md:row-start-1 md:block `}
        >
          <EventCalendar
            initialDate={new Date()}
            onDateClick={(date: string) => {
              setEventDateTime({ ...eventDateTime, date: date });
            }}
          />
        </div>

        <div className="md:col-span-4 md:col-start-5">
          <div>
            <div className="rounded-2xl pl-8">
              <div className="mt-4 text-base font-medium text-[#636e91]">
                <p className="mb-1 truncate whitespace-normal text-navy-700 md:whitespace-nowrap">
                  {article.title.length > 50
                    ? `${article.title.substring(0, 50)}...`
                    : article.title}
                </p>
                {article.scheduledAt !== undefined ? (
                  <p className="whitespace-normal">
                    Scheduled to be published on{' '}
                    {moment(article.scheduledAt).format('MM/DD/YYYY')} at{' '}
                    {moment(article.scheduledAt).format('hh:mm A')}
                  </p>
                ) : (
                  <p className="whitespace-normal">
                    Please choose the date and time for publishing your article
                  </p>
                )}
              </div>

              <div className="col-span-4 col-start-1 row-start-1 grid md:grid-cols-7 md:grid-rows-1 lg:grid-cols-7 lg:grid-rows-1">
                <div className="lg:col-span-4 lg:col-start-5 lg:row-start-0 md:col-span-4 md:col-start-1 md:row-start-1 md:ml-3 lg:ml-0  ">
                  {!isFutureDate() ? (
                    <p className="px-2 text-center text-sm font-bold !text-red-500">
                      Please choose a time in the future
                    </p>
                  ) : (
                    <div className="w-full sm:flex sm:items-center sm:justify-center ">
                      <span
                        className="mt-9 block flex items-center text-3xl font-bold text-blue-900"
                        onClick={() => {
                          if (window.innerWidth < 768) {
                            setShowCalendarOnSmallScreen(
                              !showCalendarOnSmallScreen
                            );
                          }
                        }}
                      >
                        {moment(eventDateTime.date).format('MM/DD/YYYY')}
                        <IoIosArrowDown size={30} className="ml-2  md:hidden" />
                      </span>
                      {showCalendarOnSmallScreen && (
                        <div className="my-4 block rounded-[20px] dark:text-white md:hidden">
                          <EventCalendar
                            initialDate={new Date()}
                            onDateClick={(date: string) => {
                              setEventDateTime({
                                ...eventDateTime,
                                date: date,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className=" lg:col-span-3 lg:col-start-1 lg:row-start-1 md:ml-0 md:col-start-1 md:row-start-2 ">
                  <TimePicker
                    eventDateTime={eventDateTime}
                    setEventDateTime={setEventDateTime}
                  />
                </div>
              </div>

              <Button
                type="blue"
                label="Confirm"
                loading={creating}
                disabled={!isFutureDate() || article.scheduledAt !== undefined}
                className="mr-4"
                onClick={() => {
                  create({
                    time: getSelectedDateTime().toISOString(),
                    businessArticle: article['@id'],
                  });
                }}
              />
              <Button type="blue" label="Cancel" onClick={close} />

              <div className="mt-8 h-[250px] overflow-auto">
                <TimeLine articlesList={retrieved} loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageCard>
  );
};

export default Calendar;
export type { CalendarEventDateTime };
