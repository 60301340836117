import { AiOutlineLoading } from 'react-icons/ai';
import { IoCloudDoneOutline, IoCloudOfflineOutline } from 'react-icons/io5';
import useAutosave from '../../../hooks/useAutosave';
import TResource from './type';

interface AutosaveProps {
  article: TResource;
  content: string;
  title: string;
  loading: boolean;
  liveStream?: boolean;

  updated: boolean;
  update: (
    article: TResource,
    data: { content: string; title: string }
  ) => void;
  editorChanged: boolean;
  setEditorChanged: (changed: boolean) => void;
}

const Autosave = ({
  article,
  content,
  title,
  loading,
  liveStream,
  updated,
  update,
  editorChanged,
  setEditorChanged,
}: AutosaveProps) => {
  const { autosaveStatus } = useAutosave({
    article,
    update,
    content,
    title,
    loading,
    liveStream,
    updated,
    editorChanged,
    setEditorChanged,
  });

  return (
    <div>
      <p className="relative mr-2 pb-2">
        {liveStream ? (
          <></>
        ) : autosaveStatus === 'saving' ? (
          <AiOutlineLoading className="animate-spin text-blue-500" size={28} />
        ) : autosaveStatus === 'saved' ? (
          <IoCloudDoneOutline color="green" size={28} />
        ) : (
          <IoCloudDoneOutline color="black" size={28} />
        )}
      </p>
    </div>
  );
};

export default Autosave;
