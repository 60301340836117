import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  generateFullDayTimeOptions,
  parseTime,
  findNearestTimeSlot,
} from './TimepickerUtils';
import TimeDropdown from './TimeDropdown';
import { CalendarEventDateTime } from '..';

interface TimePickerProps {
  eventDateTime: CalendarEventDateTime;
  setEventDateTime: Dispatch<SetStateAction<CalendarEventDateTime>>;
}

const TimePicker = ({ eventDateTime, setEventDateTime }: TimePickerProps) => {
  const [timeOptions, setTimeOptions] = useState<string[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>('');

  useEffect(() => {
    const options = generateFullDayTimeOptions();
    setTimeOptions(options);
    const defaultTime = findNearestTimeSlot(options, eventDateTime);
    setSelectedTime(defaultTime);
  }, []);

  const handleTimeChange = (selected: string) => {
    setSelectedTime(selected);
    const [hour, minute] = parseTime(selected);
    setEventDateTime({
      ...eventDateTime,
      hour: hour.toString(),
      minute: minute.toString(),
    });
  };

  return (
    <div className="col-start-1 row-span-1 row-start-2 mb-8 mt-8 text-3xl font-bold text-blue-900 ">
      <div className=" w-60">
        <TimeDropdown
          options={timeOptions}
          selectedTime={selectedTime}
          onChange={handleTimeChange}
        />
      </div>
    </div>
  );
};

export default TimePicker;
