import { PageCard } from '../components/card/PageCard';
import Header from '../apiplatform/components/base/Header';
import CenteredButtons from '../apiplatform/components/base/CenteredButtons';
import Button from '../components/fields/Button';
import TResource from '../apiplatform/components/businessarticle/type';


interface UnsavedArticleProps {
  article: TResource;
  updated: boolean;
  update: (article: TResource, values: any) => void;
  loading: boolean;
  content: string;
  title: string;
  onClose: () => void;
}
const UnsavedArticle = ({
  article,
  updated,
  update,
  loading,
  content,
  title,
  onClose,
}: UnsavedArticleProps) => {
  return (
    <PageCard>
      <Header text="" />

      <p>
        Are you sure you want to close <b>{article.title}</b>?  Unsaved
        changes will be lost.
      </p>

      <CenteredButtons modal>
        <Button
          type="blue"
          label="Save Changes"
          loading={loading}
          completed={updated && !loading}
          onClick={() => {
            update(article, { content: content, title: title });
            onClose();
          }}
        />
        <Button
          type="blue"
          label="Discard Changes"
          onClick={onClose}
          leftMargin="modal"
        />
      </CenteredButtons>
    </PageCard>
  );
};

export default UnsavedArticle;
