import Button from '../../../components/fields/Button';
import CopyToClipboard from '../../../Modal/CopyToClipboard';
import WordpressModal from '../../../Modal/WordPress/WordpressModal';
import TwitterModal from '../../../Modal/Twitter/TwitterModal';
import EmptyModalBox from '../base/modal/EmptyModalBox';
import Calendar from '../../../views/admin/main/applications/calendar';
import TooltipHorizon from 'components/tooltip';
import Delete from '../businessarticletitle/Delete';
import { MediaObject } from 'apiplatform/interfaces/MediaObject';
import { Link, useParams } from 'react-router-dom';
import TResource from './type';
import { PagedCollection } from '../../interfaces/Collection';
import CenteredButtons from '../base/CenteredButtons';
import ContentStatus from '../../../Modal/ContentStatus';
import { MutableRefObject, useEffect, useState } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { ScheduleArticleSending } from '../../interfaces/ScheduleArticleSending';
import useUpdate from '../../../hooks/useUpdate';
import { CalendarEventDateTime } from '../../../views/admin/main/applications/calendar';
import MobileButtons from './MobileButtons';

interface ArticleButtonsProps {
  article: TResource;
  wpRetrieved: PagedCollection<TResource> | null;
  editorRef: MutableRefObject<TinyMCEEditor>;
  wpLoading: boolean;
  updated: boolean;
  update: (article: TResource, values: any) => void;
  loading: boolean;
  content: string;
  title: string;
  onClose: () => void;
}

const ArticleButtons = ({
  article,
  editorRef,
  wpRetrieved,
  wpLoading,
  updated,
  update,
  loading,
  content,
  title,
  onClose,
}: ArticleButtonsProps) => {
  const [ArticleStatus, setArticleStatus] = useState(article.status);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleToggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <>
      <CenteredButtons leftAlign={article.type !== 'article'}>
        {article.type === 'article' && (
          <>
            <Button
              type="blue"
              label="Save"
              customButton={`rounded-lg ${
                loading ? 'px-6' : 'px-4'
              } py-1 mb-2 hidden  lg:inline-block sm:w-auto text-sm sm:text-base sm:leading-normal truncate`}
              customSpinner="absolute left-0.5 top-2 -mt-0.5"
              loading={loading}
              completed={updated && !loading}
              onClick={() => {
                update(article, { content: content, title: title });
              }}
            />
            {/* Cancel Calendar */}
            {ArticleStatus === 'scheduled' && (
              <Button
                type="blue"
                label="Cancel Schedule"
                loading={loading}
                customButton={`rounded-lg ${loading ? 'px-6' : 'px-4'} py-1`}
                customSpinner="absolute left-0.5 top-2 -mt-0.5"
                leftMargin
                disabled={wpRetrieved && !wpRetrieved['@id']}
                onClick={() => {
                  update(article, {
                    scheduledAt: null,
                    businessArticle: article['@id'],
                  });
                  update(article, { status: 'new' });
                  setTimeout(() => {
                    setArticleStatus('new');
                    onClose();
                  }, 1000);
                }}
              />
            )}

            {ArticleStatus === 'new' && (
              <EmptyModalBox
                width={800}
                item={
                  <TooltipHorizon
                    content={
                      !wpRetrieved ? (
                        <>
                          Please Setup <b>CMS Integration</b> first
                        </>
                      ) : null
                    }
                  >
                    <Button
                      type="blue"
                      label="Schedule"
                      customButton="rounded-lg px-4 py-1 mb-2 w-full lg:w-auto text-sm hidden lg:block lg:text-base truncate"
                      leftMargin
                      disabled={!wpRetrieved || wpRetrieved['@id'] === ''}
                    />
                  </TooltipHorizon>
                }
              >
                <Calendar
                  article={article}
                  close={onClose}
                  onScheduleConfirmed={() => setArticleStatus('scheduled')}
                />
              </EmptyModalBox>
            )}

            {(ArticleStatus === 'sent' && article.url != null && (
              <Button
                label="View on Website"
                type="blue"
                customButton="rounded-lg px-4 py-1 mb-2 w-full lg:w-auto text-sm  hidden lg:block lg:text-base truncate"
                leftMargin
                onClick={() => window.open(article.url, '_blank')}
              />
            )) ||
              (article.status === 'sent' || wpLoading ? null : (
                <EmptyModalBox
                  item={
                    <TooltipHorizon
                      content={
                        !wpRetrieved || wpRetrieved['@id'] === '' ? (
                          <>
                            Please Setup <b>CMS Integration</b> first
                          </>
                        ) : null
                      }
                    >
                      <Button
                        label="Publish Now"
                        customButton="rounded-lg px-4 py-1 mb-2 w-full lg:w-auto text-sm hidden lg:block lg:text-base truncate"
                        className={`${
                          ArticleStatus === 'sent' ? 'hidden' : ''
                        }`}
                        type="blue"
                        disabled={!wpRetrieved || wpRetrieved['@id'] === ''}
                        leftMargin
                      />
                    </TooltipHorizon>
                  }
                >
                  <WordpressModal
                    schedule={false}
                    article={article}
                    wordpress={wpRetrieved}
                    articleScheduled={article}
                  />
                </EmptyModalBox>
              ))}

            <EmptyModalBox
              item={
                <Button
                  type="blue"
                  label="Social Media Share"
                  leftMargin
                  customButton="rounded-lg px-4 py-1 mb-2 w-full lg:w-auto text-sm  hidden lg:block lg:text-base truncate sm:leading-normal"
                />
              }
            >
              <TwitterModal article={article} articleScheculed={article} />
            </EmptyModalBox>

            <EmptyModalBox
              item={
                <CopyToClipboard
                  editorRef={editorRef}
                  title={article.title}
                  extra="hidden lg:block"
                />
              }
            >
              <ContentStatus
                setArticleStatus={(status) => {
                  update(article, { status });
                  setArticleStatus(status);
                }}
              />
            </EmptyModalBox>
          </>
        )}

        {(article.type === 'audio' || article.type === 'video') && (
          <>
            <Button
              type="blue"
              label="Save"
              customButton={`rounded-lg ${
                loading ? 'px-6' : 'px-4'
              } py-1 mb-2 w-full sm:w-auto text-sm sm:text-base truncate`}
              customSpinner="absolute left-0.5 top-2 -mt-0.5"
              loading={loading}
              completed={updated && !loading}
              onClick={() => {
                update(article, { title: title });
              }}
            />
            <EmptyModalBox
              item={
                <Link
                  to={(article.mediaObject as MediaObject).contentUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    type="blue"
                    customButton="rounded-lg px-4 py-1 mb-2 w-full sm:w-auto text-sm sm:text-base truncate"
                    leftMargin
                    label="Download"
                  />
                </Link>
              }
            >
              <ContentStatus
                setArticleStatus={(status) => {
                  update(article, { status });
                  setArticleStatus(status);
                }}
              />
            </EmptyModalBox>
          </>
        )}

        <EmptyModalBox
          item={
            <Button
              type="red"
              label={
                article.type === 'audio' || article.type === 'video'
                  ? 'Delete Clip'
                  : 'Delete Article'
              }
              leftMargin
              customButton="rounded-lg lg:px-4 hidden lg:inline-block py-1 mb-2 w-full sm:w-auto text-sm sm:text-base truncate"
            />
          }
        >
          <Delete article={article} close={onClose} />
        </EmptyModalBox>
      </CenteredButtons>
      <CenteredButtons>
        <MobileButtons
          article={article}
          wpRetrieved={wpRetrieved}
          editorRef={editorRef}
          wpLoading={wpLoading}
          updated={updated}
          loading={loading}
          content={content}
          title={title}
          onClose={onClose}
          handleToggleDropdown={handleToggleDropdown}
          update={update}
        />
      </CenteredButtons>
    </>
  );
};

export default ArticleButtons;
