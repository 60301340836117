import { EditorProps, State, UI } from './types';
import EditorControls from './EditorControls';
import EditorBar from './EditorBar';
import { add5sLeft, add5sRight, createState, initUI } from './utils';
import { useEditor, useUI } from './hooks';
import { FaRegFileAudio } from 'react-icons/fa';

const Editor = ({
  video,
  width,
  timeFrame,
  onLeftClick,
  onRightClick,
  onApproveClick,
}: EditorProps) => {
  const ui: UI = useUI();

  const startSeconds = timeFrame?.startSeconds;

  var state: State = createState(
    width ? width : 750,
    startSeconds && startSeconds > 0 ? startSeconds : 0,
    timeFrame?.endSeconds
  );

  useEditor(state, ui);

  return (
    <div className="h-full w-full pb-[20px]" ref={ui.editorAreaRef}>
      <div className="relative m-auto" style={{ width: `${state.width}px` }}>
        <span className="absolute left-[50%] top-[40%] ml-[-30px] text-[60px]">
          <FaRegFileAudio />
        </span>
        <video
          className="relative w-full bg-gray-50"
          src={
            typeof video === 'string'
              ? video
              : window.URL.createObjectURL(video)
          }
          ref={ui.videoRef}
        />
        <span className="z-index-10 absolute left-[50%] top-[50%] ml-[-20px] mt-[-38px]">
          <div
            ref={ui.spinnerRef}
            className="hidden h-10 w-10 animate-spin rounded-full border-4 border-gray-300 border-t-blue-900"
          />
        </span>
        <EditorBar ui={ui} width={state.width} />
        <EditorControls
          ui={ui}
          state={state}
          onLeftClick={() => {
            add5sLeft(ui, state);
            onLeftClick && onLeftClick();
          }}
          onRightClick={() => {
            add5sRight(ui, state);
            onRightClick && onRightClick();
          }}
          onApproveClick={onApproveClick}
        />
      </div>
    </div>
  );
};

export default Editor;
