import {
  PropsWithChildren,
  ReactNode,
  cloneElement,
  Children,
  isValidElement,
} from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import IconButton from 'components/fields/IconButton';
import { MdClose } from 'react-icons/md';
import TooltipHorizon from 'components/tooltip';

interface ModalBoxProps extends PropsWithChildren {
  item: ReactNode;
  width?: number;
  height?: number;
  extra?: string;
  closeLabel?: string;
  runOnClose?: () => void;
}

const EmptyModalBox = ({
  item,
  children,
  width,
  height,
  extra,
  runOnClose,
  closeLabel,
}: ModalBoxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onClose: runOnClose ? runOnClose : () => {},
  });

  const close = (event: Event) => {
    if (event) {
      event.preventDefault();
    }
    onClose();
  };

  return (
    <>
      <span onClick={onOpen} className={`inline-block `}>
        {item}
      </span>
      <Modal blockScrollOnMount={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay bg="rgba(0,0,0,0.7)" />
        <ModalContent
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          h="100vh"
          // onClick={(event) => {
          //   // fix: click overlay -> close
          //   if (
          //     (event.target as HTMLElement)
          //       .getAttribute('id')
          //       ?.match(/^chakra-modal/)
          //   ) {
          //     onClose();
          //   }
          // }}
        >
          <ModalBody
            h={height ? height : 'min-content'}
            sx={{
              width: '100%', 
              '@media (min-width: 768px)': {
                width: width ? `${width * 0.8}px` : '80%',
              },
              '@media (min-width: 1024px)': {
                width: width ? `${width}px` : '600px',
              },
            }}
            className={`relative max-h-screen py-4 ${extra}`}
          >
            <span
              onClick={onClose}
              className="absolute right-4 top-8 z-[1000001]"
            >
              <TooltipHorizon content={closeLabel ? closeLabel : 'Close'}>
                <IconButton extra="bg-red-500 hover:bg-red-600">
                  <MdClose />
                </IconButton>
              </TooltipHorizon>
            </span>
            {Children.map(children, (child) => {
              if (isValidElement(child)) {
                return cloneElement(child, {
                  close: close,
                  ...child.props,
                });
              }
            })}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmptyModalBox;
